<script setup lang="ts">
import { useAuthStore } from "@/stores/auth";
import { useIataStore } from "@/stores/iata";

const auth: any = useAuthStore();
const iata: any = useIataStore();

const getIATA = async () => {
  try {
    const [responseIATA] = await Promise.all([
      axios.get(`/dashboard/api/sales_points/iata`),
    ]);

    if (responseIATA.data.values.length > 0) {
      iata.SET_IATA(responseIATA.data.values);
    }
  } catch (error) {
    console.error(
      "Erreur lors de la récupération des données de l'agence:",
      error
    );
  }
};

function capitalizeFirstLetter(string: string) {
  if (string?.length > 0)
    return (
      string?.charAt(0)?.toUpperCase() + string?.slice(1).toLocaleLowerCase()
    );
  else return string;
}

// Mettez un watcher sur la propriété qui indique l'état d'authentification
watch(
  () => auth.token,
  (newValue) => {
    // Si l'état d'authentification change et devient `true`, déclencher getIATA
    if (newValue && !iata.getIata && auth.groups[0] !== "Metis") {
      getIATA();
    }
  },
  { immediate: true } // Cela déclenchera le watcher immédiatement après la création du composant
);

watch(
  () => auth.groups && auth.groups[0],
  (newValue) => {
    if (newValue === "Metis") {
      iata.SET_IATA(null);
    }
  }
);
</script>

<template>
  <div class="mr-5">
    <p>
      <span v-if="auth.userDetails" style="font-weight: bold">
        <!-- Bonjour -->
        {{ $t("components-lc-Full-vertical-header-Iata.hello") }}
        {{ capitalizeFirstLetter(auth.userDetails.given_name) || "" }}
        {{ auth.userDetails.family_name.toUpperCase() || "" }} -
      </span>
      <span v-if="auth.getGroups" style="font-weight: bold">
        {{ auth.groups[0] }}</span
      >
      <template v-if="iata.iata">
        <span v-if="iata?.getIata[0]?.iata">
          (IATA :
          <span style="font-weight: bold">{{
            iata?.getIata[0]?.iata || ""
          }}</span
          >)
        </span>
      </template>
    </p>
  </div>
</template>
