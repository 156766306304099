/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ReceiptIcon,
  HelpIcon,
  HeartHandshakeIcon,
  PlaneIcon,
  HotelServiceIcon,
  CarIcon,
  TrainIcon,
  BellIcon,
  FolderIcon,
  Book2Icon,
  DashboardIcon,
  BoxIcon,
} from "vue-tabler-icons";

export interface menu {
  header?: string;
  title?: string;
  icon?: any;
  to?: string;
  divider?: boolean;
  chip?: string;
  chipColor?: string;
  chipVariant?: string;
  chipIcon?: string;
  children?: menu[];
  disabled?: boolean;
  subCaption?: string;
  class?: string;
  extraclass?: string;
  type?: string;
}
const runtimeConfig = useRuntimeConfig();
const horizontalItems: menu[] = [
  {
    title: "reservation",
    icon: HeartHandshakeIcon,
    to: "#",
    children: [
      {
        title: "flights",
        icon: PlaneIcon,
        to: "/booking/flight",
      },
      // {
      //   title: "Séjours",
      //   icon: HotelServiceIcon,
      //   to: "/booking/classic",
      // },
      // {
      //   title: "Voitures",
      //   icon: CarIcon,
      //   to: "/booking/demographical",
      // },
      // {
      //   title: "Trains",
      //   icon: TrainIcon,
      //   to: "/booking/minimal",
      // },
      // {
      //   title: "Package",
      //   icon: BoxIcon,
      //   to: "/booking/package",
      // },
      // {
      //   title: "Réceptif",
      //   icon: ReceiptIcon,
      //   to: "/booking/receptive",
      // },
    ],
  },
  {
    title: "folders",
    icon: FolderIcon,
    to: "/folder",
  },
  {
    title: "notifications",
    icon: BellIcon,
    to: "/notification",
  },
  {
    title: "crm",
    icon: DashboardIcon,
    to: runtimeConfig.dashboardFront,
  },
  {
    title: "edv",
    icon: Book2Icon,
    to: "#",
    children: [
      {
        title: "jdv",
        icon: Book2Icon,
        to: "/journal/sales-journal",
      },
      {
         title: "uettr",
         icon: Book2Icon,
         to: "/journal/uettr",
      },
    ],
  },
  {
    title: "help",
    icon: HelpIcon,
    to: "/help",
  },
];

export default horizontalItems;
