<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
// import { shallowRef } from "vue";
import { useDisplay } from "vuetify";
import { Book2Icon } from "vue-tabler-icons";
import HorizontalItems from "./horizontalItems";
import AlertsList from "@/components/AlertsList.vue";
import { useIataStore } from "@/stores/iata";
import { useAuthStore } from "@/stores/auth";
const { mdAndUp } = useDisplay();
const sidebarMenu = ref(HorizontalItems);

const iata: any = useIataStore();
const auth: any = useAuthStore();

const alerts = ref([]);
const ads = ref<any>([]);
const notifs = ref(0);

const waitForToken = () => {
  return new Promise((resolve) => {
    const checkToken = () => {
      const token = localStorage.getItem("keycloak-token");
      if (token) {
        resolve(token);
      } else {
        setTimeout(checkToken, 100);
      }
    };
    checkToken();
  });
};
const getAlerts = async () => {
  const [response] = await Promise.all([
    axios.get("/dashboard/api/alerts/read/all-public"),
  ]);
  if (response.data.values) {
    alerts.value = response.data.values;
  }
};

const getNotificationsCount = async () => {
  const [response] = await Promise.all([
    axios.get("/aerial/notifications/count/all"),
  ]);
  if (response.data.value) {
    notifs.value = response.data.value;
  }
};
const waitForIata = () => {
  return new Promise((resolve) => {
    const checkIATA = () => {
      const newIATA = iata.iata;
      if (newIATA) {
        resolve(newIATA);
      } else {
        setTimeout(checkIATA, 100);
      }
    };
    checkIATA();
  });
};
const getAds = async () => {
  const waitForIata = () => {
    return new Promise((resolve) => {
      const checkIATA = () => {
        const newIATA = iata.iata;
        if (newIATA) {
          resolve(newIATA);
        } else {
          setTimeout(checkIATA, 100);
        }
      };
      checkIATA();
    });
  };
  const iataInfo: any = await waitForIata();
  const currentDate = new Date();
  const currentHour = currentDate.getHours();
  const [response] = await Promise.all([
    axios.post("/dashboard/api/ads/read/all-public", {
      gie: iataInfo[0]?.gie,
      periodAM: currentHour >= 9 && currentHour < 14,
      periodPM: currentHour >= 14 && currentHour < 19,
    }),
  ]);
  if (response.data.values) {
    ads.value = response.data.values;
    updateLocalStorage("ads", ads.value);
  }
};
const addUettr = async () => {
  if (auth?.getUserDetails?.groups.includes("Metis")) {
    const find = sidebarMenu.value.findIndex(
      (item) => item.title === "Etat des ventes"
    );
    if (find) {
      sidebarMenu.value[find].children.push({
        title: "UETTR",
        icon: Book2Icon,
        to: "/journal/uettr",
      });
    }
  } else {
    await waitForIata();

    const find = sidebarMenu.value.findIndex(
      (item) => item.title === "Etat des ventes"
    );
    if (
      find &&
      (iata.iata[0].name === "AdenisPDV" ||
        iata.iata[0].name === "Tourcom test siege" ||
        auth.getUserDetails.email === "reouven@everbloo.io" ||
        auth.getUserDetails.email === "bmolle@metisdigital.io" ||
        (auth.getUserDetails.email === "sonia@tourcom.fr" &&
          !auth.getUserDetails.groups.includes("Tourcom")))
    ) {
      sidebarMenu.value[find].children.push({
        title: "UETTR",
        icon: Book2Icon,
        to: "/journal/uettr",
      });
    }
  }
};

onMounted(async () => {
  await waitForToken();
  addUettr();
  getAlerts();
  getNotificationsCount();
  getAds();
});
</script>

<template>
  <template v-if="mdAndUp">
    <div class="horizontalMenu border-bottom bg-surface position-relative">
      <div class="px-6">
        <ul class="gap-1 horizontal-navbar mx-lg-0 mx-3 justify-center">
          <v-col cols="2"></v-col>
          <!---Menu Loop -->
          <v-col cols="8">
            <v-row justify="center" align="center">
              <li v-for="(item, i) in sidebarMenu" :key="i" class="navItem">
                <!---If Has Child -->
                <LcFullHorizontalSidebarNavCollapse
                  v-if="item.children"
                  :item="item"
                  :level="0"
                />
                <!---Single Item-->
                <LcFullHorizontalSidebarNavItem v-else :item="item" />
                <!---End Single Item-->
                <h4
                  v-if="item?.title === 'notifications' && notifs > 0"
                  class="circle-notif"
                >
                  {{ notifs > 99 ? "99+" : notifs }}
                </h4>
              </li>
            </v-row>
          </v-col>
          <v-col cols="2" align="center">
            <AlertsList v-if="alerts.length" :items="alerts" />
          </v-col>
        </ul>
      </div>
    </div>
  </template>
</template>
<style>
.circle-notif {
  margin-top: -38px;
  margin-left: 20px;
  position: absolute;
  width: 20px; /* Ajustez la taille du cercle selon vos besoins */
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  background-color: #ffae1f;
  text-align: center;
}
</style>
